import React, { useState, useEffect } from 'react'
import { useQuery, useLazyQuery } from '@apollo/client'
import { useSession } from '@slerp/accounts'
import { SearchOutlined, SnippetsOutlined } from '@ant-design/icons'
import { remove } from 'lodash'
import { useLocation } from 'react-router-dom'
import {
  Button,
  Row,
  Col,
  Input,
  Form,
  Select,
  Tooltip,
  DatePicker,
  Modal,
  Tag,
  Divider,
  Checkbox,
  Typography
} from 'antd'
import isEmpty from 'lodash/isEmpty'
import { uuid } from 'uuidv4'
import { LocationIcon, CalendarIcon, FilterIcon } from '@slerp/assets'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { QUERY_ORDERS, QUERY_ORDERS_BY_EMAIL } from './OrderQueries'
import { QUERY_LOCATIONS } from './../Locations/LocationQueries'
import Reports from './Reports'
import OrdersList from './OrdersList'
import moment from 'moment-timezone'
import { SelectPrefixWrapper } from './../Widgets'
import { GET_DATES_WITH_ORDERS_BY_MONTH } from 'components/Locations/Dashboard/DashboardQueries'
import { formatDate } from 'components/Locations/Dashboard/helpers'
import classnames from 'classnames'
import debounce from 'lodash/debounce'
import { isValidEmail } from '@slerp/helpers'
import { QUERY_MERCHANT_SETTING } from 'components/Merchants/MerchantQueries'
import styled from '@emotion/styled'

const dateFormat = 'DD/MM/YYYY'

interface LocationType {
  hash: string
  pathname: string
  search: string
  state: undefined
}

interface StoreType {
  archived_at: Date | null
  id: string
  name: string
}

const setSearchParams = (location: LocationType) => {
  return new URLSearchParams(location.search)
}

const Orders = () => {
  const { merchant } = useSession()
  const searchParams = setSearchParams(useLocation())
  const transactionId = searchParams.get('transaction_id') || ''
  const ORDER_STATUSES = [
    { value: 'pending', label: 'Pending' },
    { value: 'accepted', label: 'Accepted' },
    { value: 'fulfilled', label: 'Fulfilled' },
    { value: 'refunded', label: 'Refunded' },
    { value: 'cancelled', label: 'Cancelled' },
    { value: 'rejected', label: 'Rejected' }
  ]
  const allStatusValues = ORDER_STATUSES.map((status) => status.value)
  const statusFromSearchParams = searchParams.get('status')
  const orderStatus = statusFromSearchParams ? [statusFromSearchParams] : 'all'

  const { data: storesData, loading: storesLoading } = useQuery(
    QUERY_LOCATIONS,
    {
      variables: { merchantSlug: merchant.slug }
    }
  )

  const [startDate, setStartDate] = useState<moment.Moment | null | undefined>(
    null
  )
  const [endDate, setEndDate] = useState<moment.Moment | null | undefined>(null)
  const [statuses, setStatuses] = useState<string[] | 'all'>(orderStatus)
  const [storeIds, setStoreIds] = useState(
    storesData?.stores.map((store) => store.id) || []
  )
  const [storeIdsFilter, setStoreIdsFilter] = useState<string[]>([])
  const [activeStores, setActiveStores] = useState([])
  const [limit, setLimit] = useState(merchant === 'crosstown' ? 1000 : 100)
  const [showGenerateReportModal, setShowGenerateReportModal] = useState(false)
  const [allOrders, setAllOrders] = useState([])
  const [searchOrder, setSearchOrder] = useState(`%${transactionId}%`)
  const [switchData, setSwitchData] = useState(false)

  const { data: merchantSettingData } = useQuery(QUERY_MERCHANT_SETTING, {
    variables: { id: merchant.id },
    fetchPolicy: 'no-cache'
  })

  const { data, refetch, loading } = useQuery(QUERY_ORDERS, {
    variables: {
      slug: merchant.slug,
      statuses: statuses === 'all' ? allStatusValues : statuses,
      startDate,
      endDate,
      storeIds,
      limit,
      query: searchOrder
    },
    notifyOnNetworkStatusChange: true
  })

  const [
    getOrdersByEmail,
    { data: ordersByEmail, loading: loadingOrdersByEmail }
  ] = useLazyQuery(QUERY_ORDERS_BY_EMAIL, {
    onCompleted: () => setSwitchData(true)
  })

  useEffect(() => {
    if (data && data.orders) setAllOrders(data.orders)
  }, [data])

  const merchantId = merchant.id
  const currentDate = moment()
  const datesWithOrdersByMonth = useQuery(GET_DATES_WITH_ORDERS_BY_MONTH, {
    skip: true,
    fetchPolicy: 'no-cache',
    variables: { merchantId, date: formatDate(currentDate) }
  })

  const fetchDatesWithOrdersByMonth = datesWithOrdersByMonth.refetch
  const [datesWithOrders, setDatesWithOrders] = useState<{
    [key: string]: string[]
  }>({})

  // No longer being used
  const reloadOrders = () => {
    refetch({
      slug: merchant.slug,
      statuses: statuses === 'all' ? allStatusValues : statuses,
      query: '%%',
      startDate,
      endDate,
      storeIds,
      limit
    })
  }

  useEffect(() => {
    setStoreIds(storesData?.stores.map((store: StoreType) => store.id) || [])
    setActiveStores(
      storesData?.stores.filter((store: StoreType) => !store.archived_at) || []
    )
  }, [storesData])

  const updateLimit = (value?: number) => {
    setLimit(value)
  }

  const updateStore = (values: String[]) => {
    let storeFilters = values

    if (!isEmpty(values) && values[0] === 'all') {
      storeFilters = values.filter((value: String) => value !== 'all')
    }

    if (!isEmpty(values) && values[values.length - 1] === 'all') {
      storeFilters = ['all']
    }

    if (storeFilters.includes('all')) {
      setStoreIdsFilter([])
      setStoreIds(storesData?.stores.map((store: StoreType) => store.id) || [])
      return
    }

    const displayStores = !!storeFilters.length
      ? storeFilters
      : storesData?.stores.map((store: StoreType) => store.id) || []

    setStoreIdsFilter(storeFilters)
    setStoreIds(displayStores)
  }

  const handleSearchOrder = (e: string) => setSearchOrder(e)
  const debounceSearchOrder = debounce(handleSearchOrder, 400)

  useEffect(() => {
    setSwitchData(false)

    if (searchOrder === '') {
      refetch({
        query: `%%`
      })
    } else if (isValidEmail(searchOrder)) {
      getOrdersByEmail({
        variables: {
          email: { email: searchOrder },
          slug: merchant.slug,
          statuses: statuses === 'all' ? allStatusValues : statuses,
          startDate,
          endDate,
          storeIds,
          limit
        }
      })
    } else {
      refetch({
        query: `%${searchOrder}%`
      })
    }
  }, [searchOrder])

  const changeDate = (e: any) => {
    if (e) {
      setStartDate(moment(e).startOf('day'))
      setEndDate(moment(e).endOf('day'))
    } else {
      setStartDate(null)
      setEndDate(null)
    }
  }

  const toggleGenerateReportModal = () => {
    setShowGenerateReportModal(!showGenerateReportModal)
  }

  const handleCalendarChange = (date: moment.Moment) => {
    if (!date) return
    if (datesWithOrders[formatDate(date, 'YYYY-MM')]) return
    fetchDatesWithOrdersByMonth({ merchantId, date: formatDate(date) })
      .then((res) => {
        setDatesWithOrders({
          ...datesWithOrders,
          [formatDate(date, 'YYYY-MM')]: res.data.dates
        })
      })
      .catch((err) =>
        console.error(
          `Failed to fetch dates with orders on ${formatDate(
            date,
            'YYYY-MM'
          )}:`,
          err
        )
      )
  }

  useEffect(() => {
    if (!statuses || statuses.length === 0) setStatuses('all')
  }, [statuses])

  return (
    <>
      <Modal
        title={
          <>
            Generate Report
            <Tooltip
              title={
                <a
                  href='https://support.slerp.com/en_us/reporting-terminology-HJmQNBo6_'
                  target='_blank'
                  rel='noreferrer'
                >
                  Reporting
                </a>
              }
              placement='right'
            >
              <QuestionCircleOutlined className='_ml-8' />
            </Tooltip>
          </>
        }
        visible={showGenerateReportModal}
        onCancel={toggleGenerateReportModal}
        footer={null}
        width={815}
      >
        <Reports merchant={merchantSettingData?.merchant} />
      </Modal>
      <Form onFinish={reloadOrders}>
        <Row justify='space-between' gutter={8}>
          <Col>
            <Row className='orders-filter-dashboard'>
              <Col className='orders-form-item'>
                {storesData?.stores && !storesLoading ? (
                  <SelectPrefixWrapper prefixicon={<LocationIcon />}>
                    <Select
                      data-testid='order-location-filter-dropdown'
                      placeholder='Filter by Location'
                      onChange={updateStore}
                      showSearch={false}
                      value={
                        storeIdsFilter.length > 0 ? storeIdsFilter : ['all']
                      }
                      tagRender={({ value }) =>
                        value !== 'all' ? (
                          <span className='_capitalize'>{value}</span>
                        ) : (
                          <>All locations</>
                        )
                      }
                      maxTagCount='responsive'
                      className='control _w-16 -with-icon order-select'
                      mode='tags'
                      dropdownClassName='order-dropdown'
                      dropdownRender={(menu) => (
                        <div className='_py-4 _px-8'>{menu}</div>
                      )}
                      maxTagPlaceholder={() => (
                        <>
                          {storeIdsFilter.length === 0
                            ? 'All locations'
                            : `${storeIdsFilter.length} selected`}
                        </>
                      )}
                      listHeight={400}
                      listItemHeight={10}
                    >
                      <Select.Option
                        value='all'
                        data-testid='location-option-all'
                      >
                        <span>All locations</span>
                      </Select.Option>
                      {/* Hax */}
                      <Select.Option disabled className='divider'>
                        <Divider className='_my-8' />
                      </Select.Option>
                      {activeStores.map((store: StoreType) => (
                        <Select.Option
                          value={store.id}
                          key={store.id}
                          className='_px-8 _my-2'
                          data-testid={`orders-filter-by-store-${store.name}`}
                        >
                          <Checkbox
                            className='_mr-12'
                            checked={storeIdsFilter.includes(store.id)}
                          />
                          {store.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </SelectPrefixWrapper>
                ) : (
                  '...'
                )}
              </Col>
              <Col className='orders-form-item'>
                {
                  <SelectPrefixWrapper prefixicon={<FilterIcon />}>
                    <Select
                      data-testid='order-status-filter-dropdown'
                      placeholder='Filter by Status'
                      onChange={(options) => {
                        const previous = statuses
                        const upcoming = options || []
                        if (
                          !options ||
                          options.length === 0 ||
                          (!previous.includes('all') &&
                            upcoming.includes('all')) ||
                          allStatusValues.length === upcoming.length
                        )
                          return setStatuses('all')
                        return setStatuses(
                          upcoming.filter((status) => status !== 'all')
                        )
                      }}
                      showSearch={false}
                      value={statuses === 'all' ? [statuses] : statuses}
                      tagRender={({ value }) =>
                        value !== 'all' ? (
                          <span className='_capitalize'>{value}</span>
                        ) : (
                          <>All orders</>
                        )
                      }
                      maxTagCount='responsive'
                      className='control _w-16 -with-icon order-select'
                      mode='tags'
                      dropdownClassName='order-dropdown'
                      dropdownRender={(menu) => (
                        <div className='_py-4 _px-8'>{menu}</div>
                      )}
                      maxTagPlaceholder={() => (
                        <>
                          {statuses === 'all'
                            ? 'All orders'
                            : `${statuses.length} selected`}
                        </>
                      )}
                      listHeight={340}
                    >
                      <Select.Option
                        value='all'
                        data-testid='status-option-all'
                      >
                        <span>All orders</span>{' '}
                        <span className='order-count'>{`(${
                          (allOrders && allOrders.length) || 0
                        } orders)`}</span>
                      </Select.Option>
                      {/* Hax */}
                      <Select.Option disabled className='divider'>
                        <Divider className='_my-8' />
                      </Select.Option>
                      {ORDER_STATUSES.map((state) => (
                        <Select.Option
                          value={state.value}
                          key={state.value}
                          className='_px-8 _my-2'
                          data-testid={`status-option-${state.value}`}
                        >
                          <Checkbox
                            checked={statuses.includes(state.value)}
                            className='_mr-12'
                          />
                          {state.label}{' '}
                          <span className='order-count'>{`(${
                            (allOrders &&
                              allOrders.filter(
                                (order) => order.status === state.value
                              ).length) ||
                            0
                          } orders)`}</span>
                        </Select.Option>
                      ))}
                    </Select>
                  </SelectPrefixWrapper>
                }
              </Col>
              <Col className='orders-form-item'>
                <Form.Item name='date' className='control'>
                  <SelectPrefixWrapper prefixicon={<CalendarIcon />}>
                    <DatePicker
                      defaultValue={startDate ? startDate : undefined}
                      format={dateFormat}
                      onChange={changeDate}
                      placeholder='Filter by Fulfilment Date'
                      className='_pl-48'
                      onOpenChange={(open) =>
                        open && handleCalendarChange(moment())
                      }
                      onPanelChange={handleCalendarChange}
                      dateRender={(momentDate) => {
                        const formattedDate = formatDate(momentDate)
                        const key = formatDate(momentDate, 'YYYY-MM')
                        const hasOrder = (datesWithOrders[key] || []).includes(
                          formattedDate
                        )
                        return (
                          <div
                            title={formattedDate}
                            className='ant-picker-cell ant-picker-cell-in-view'
                            key={`date-picker-date-${formattedDate}`}
                          >
                            <div
                              className={classnames([
                                'ant-picker-cell-inner',
                                hasOrder ? '-has-orders' : ''
                              ])}
                            >
                              {momentDate.date()}
                              <div className='dot' />
                            </div>
                          </div>
                        )
                      }}
                    />
                  </SelectPrefixWrapper>
                </Form.Item>
              </Col>
              <Col className='orders-form-item'>
                <Form.Item>
                  <Select
                    placeholder='Limit Result'
                    data-testid='limit-result'
                    onChange={updateLimit}
                    defaultValue={limit}
                  >
                    <Select.Option value={10}>Show 10 Results</Select.Option>
                    <Select.Option data-testid='value-20' value={20}>
                      Show 20 Results
                    </Select.Option>
                    <Select.Option value={50}>Show 50 Results</Select.Option>
                    <Select.Option value={100}>Show 100 Results</Select.Option>
                    <Select.Option value={500}>Show 500 Results</Select.Option>
                    <Select.Option value={1000}>
                      Show 1000 Results
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row
              gutter={8}
              justify='end'
              className='orders-filter-dashboard'
              align='middle'
            >
              <Col className='orders-form-item'>
                <Form.Item name='query' className='control _mb-0'>
                  <Tooltip
                    title={
                      <span>
                        Type in the order ID to begin your search or type in a
                        full email address to search orders by a customer's
                        email.
                      </span>
                    }
                  >
                    <StyledInput
                      prefix={
                        <SearchOutlined className='site-form-item-icon' />
                      }
                      placeholder='Search'
                      onChangeCapture={(e) =>
                        debounceSearchOrder(e.target?.value)
                      }
                    />
                  </Tooltip>
                </Form.Item>
              </Col>
              <Col></Col>
              <Col>
                <Tooltip
                  title={
                    <span>
                      Download customised reports and statements. Find out more
                      about reporting{' '}
                      <a
                        href='https://support.slerp.com/en_us/reporting-dashboard-overview-HyV9TnE6d'
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        here
                      </a>
                      .
                    </span>
                  }
                >
                  <Button
                    icon={<SnippetsOutlined />}
                    onClick={toggleGenerateReportModal}
                  >
                    Generate Report
                  </Button>
                </Tooltip>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            {statuses !== 'all' && statuses.length > 0 && (
              <div className='_mb-16'>
                <Typography.Text className='_mr-8'>
                  Order status:
                </Typography.Text>
                {statuses.map((value) => (
                  <Tag
                    key={uuid()}
                    className='-basic'
                    closable
                    onClose={(e) => {
                      e.preventDefault()
                      setStatuses(
                        remove(statuses, (status) => status !== value)
                      )
                    }}
                  >
                    <span>{value}</span>{' '}
                    <span className='order-count'>{`(${
                      (data &&
                        data.orders &&
                        data.orders.filter((order) => order.status === value)
                          .length) ||
                      0
                    } orders)`}</span>
                  </Tag>
                ))}
              </div>
            )}
            <OrdersList
              orders={data && switchData ? ordersByEmail?.orders : data?.orders}
              loading={switchData ? loadingOrdersByEmail : loading}
              refetch={refetch}
            />
          </Col>
        </Row>
      </Form>
    </>
  )
}

const StyledInput = styled(Input)`
  width: 280px;
`

export default Orders
