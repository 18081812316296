import { AppSettings } from '@slerp/controls'

export type MERCHANT_LAYOUT_SETTING = {
  merchants_by_pk: {
    name: string
    app_settings: AppSettings[]
    integration_settings: {
      composer: {
        id: string
        enabled: boolean
      }
      crm: {
        enabled: boolean
      }
    }
  }
}

const merchant = (merchant: MERCHANT_LAYOUT_SETTING) => {
  return merchant?.merchants_by_pk
}

export const crmUrl = (merchantSetting: MERCHANT_LAYOUT_SETTING) => {
  if (merchant(merchantSetting)?.integration_settings?.crm?.enabled) {
    return 'https://app.crm.slerp.com/#/login'
  } else {
    return 'https://www.slerp.com/crm-book-a-demo/'
  }
}
