import { gql } from '@apollo/client'

export const CREATE_APP_SETTINGS = gql`
  mutation createMerchantAppSettings(
    $merchantId: uuid!
    $custom_content_enabled: Boolean
    $sections: [SectionParams]
  ) {
    createAppSettings(
      merchantId: $merchantId
      customContentEnabled: $custom_content_enabled
      sections: $sections
    ) {
      id
    }
  }
`

export const CREATE_BASIC_APP_SETTINGS = gql`
  mutation createBasicAppSettings($object: app_settings_insert_input!) {
    insert_app_settings_one(object: $object) {
      id
    }
  }
`

export const UPDATE_APP_SETTINGS = gql`
  mutation updateMerchantAppSettings(
    $id: ID!
    $custom_content_enabled: Boolean
    $sections: [SectionParams]
    $location_products_view: String
  ) {
    updateAppSettings(
      id: $id
      customContentEnabled: $custom_content_enabled
      sections: $sections
      location_products_view: $location_products_view
    ) {
      id
    }
  }
`

export const UPDATE_BASIC_APP_SETTINGS = gql`
  mutation updateBasicAppSettings(
    $updateValues: app_settings_set_input
    $id: uuid!
  ) {
    update_app_settings(where: { id: { _eq: $id } }, _set: $updateValues) {
      returning {
        id
      }
    }
  }
`

export const QUERY_APP_SETTINGS = gql`
  query getAppSettings($merchantId: uuid!) {
    app_settings(where: { merchant_id: { _eq: $merchantId } }) {
      id
      custom_content_enabled
      sections
      location_products_view
    }
  }
`

export const SEND_APP_NOTIFICATION = gql`
  mutation sendAppNotifications(
    $merchantId: ID!
    $startDate: String!
    $endDate: String!
    $storeIds: [ID]!
    $title: String!
    $notificationTitle: String!
    $notificationBody: String!
    $hasOrdered: Boolean
    $hasStamped: Boolean
    $hasUnredeemedRewards: Boolean
    $hasBirthday: Boolean
    $count: Int
    $exception: Boolean
    $sendToAll: Boolean
    $isPublished: Boolean
    $publishOn: String
  ) {
    sendAppNotification(
      merchantId: $merchantId
      startDate: $startDate
      endDate: $endDate
      storeIds: $storeIds
      title: $title
      notificationTitle: $notificationTitle
      notificationBody: $notificationBody
      hasOrdered: $hasOrdered
      hasStamped: $hasStamped
      hasUnredeemedRewards: $hasUnredeemedRewards
      hasBirthday: $hasBirthday
      count: $count
      exception: $exception
      sendToAll: $sendToAll
      isPublished: $isPublished
      publishOn: $publishOn
    )
  }
`

export const PREVIEW_CAMPAIGN_CUSTOMER_DEVICE_TOKENS = gql`
  query previewCampaignCustomerDeviceTokens(
    $startDate: String!
    $endDate: String!
    $storeIds: [ID]!
    $hasOrdered: Boolean
    $hasStamped: Boolean
    $hasUnredeemedRewards: Boolean
    $hasBirthday: Boolean
    $count: Int
    $exception: Boolean
    $sendToAll: Boolean
  ) {
    previewCampaignCustomerDeviceTokens(
      startDate: $startDate
      endDate: $endDate
      storeIds: $storeIds
      hasOrdered: $hasOrdered
      hasStamped: $hasStamped
      hasUnredeemedRewards: $hasUnredeemedRewards
      hasBirthday: $hasBirthday
      count: $count
      exception: $exception
      sendToAll: $sendToAll
    ) {
      id
    }
  }
`

export const LIST_CAMPAIGNS = gql`
  query listCampaigns($isPublished: Boolean) {
    listCampaigns(isPublished: $isPublished) {
      id
      title
      notificationTitle
      notificationBody
      notificationsSentTotal
      notificationsSentSuccess
      publishOn
      insertedAt
    }
  }
`

export const UPDATE_CAMPAIGN = gql`
  mutation updateCampaign($id: ID!, $campaign: CampaignParams!) {
    updateCampaign(id: $id, campaign: $campaign) {
      id
      title
      notificationTitle
      notificationBody
      publishOn
      insertedAt
    }
  }
`

export const ARCHIVE_CAMPAIGN = gql`
  mutation archiveCampaign($id: ID!) {
    archiveCampaign(id: $id) {
      id
    }
  }
`
