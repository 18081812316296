import React from 'react'
import { Col, Tabs } from 'antd'
import CampaignHistory from './CampaignHistory'
import { useGlobalQuery } from 'components/Utils/useGlobalQuery'
import Loading from 'components/Utils/Loading'

const AppCampaigns = () => {
  const { isLoading } = useGlobalQuery()

  if (isLoading) return <Loading />

  return (
    <Col className='_mt-16' data-testid='app-campaigns'>
      <Tabs destroyInactiveTabPane={true}>
        <Tabs.TabPane tab='Campaign History' key='1'>
          <Col>
            <CampaignHistory isPublished={true} />
          </Col>
        </Tabs.TabPane>
        <Tabs.TabPane tab='Scheduled Campaigns' key='2'>
          <Col>
            <CampaignHistory isPublished={false} />
          </Col>
        </Tabs.TabPane>
      </Tabs>
    </Col>
  )
}

export default AppCampaigns
