import { gql } from '@apollo/client'

const QUERY_MERCHANT_SETTING = gql`
  query getMerchantSetting($merchant: uuid!) {
    merchants_by_pk(id: $merchant) {
      slug
      integration_settings
    }
  }
`

const UPDATE_MERCHANT_COMPOSER = gql`
  mutation updateIntegrations($merchantId: uuid!, $setting: jsonb!) {
    update_merchants(
      where: { id: { _eq: $merchantId } }
      _append: { integration_settings: $setting }
    ) {
      affected_rows
      returning {
        integration_settings
      }
    }
  }
`
export { QUERY_MERCHANT_SETTING, UPDATE_MERCHANT_COMPOSER }
