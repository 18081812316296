import React, { useState, useEffect } from 'react'
import { useSession } from '@slerp/accounts'
import { Button, message } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { BoxIcon as ComposerIcon } from '@slerp/assets'
import { useQuery, useMutation } from '@apollo/client'
import {
  QUERY_MERCHANT_SETTING,
  UPDATE_MERCHANT_COMPOSER
} from './ComposerQueries'
import {
  getComposerId,
  getComposerEnabled,
  handleComposerSSO,
  allowComposerSSO
} from './utils'

export type MERCHANT_LAYOUT_SETTING = {
  merchants_by_pk: {
    slug: string
    integration_settings: {
      composer: {
        id: string
        enabled: boolean
      }
    }
  }
}

const Composer = () => {
  const { merchant } = useSession()
  const [data, setData] = useState<MERCHANT_LAYOUT_SETTING>({})
  const [enabled, setEnabled] = useState<Boolean>(false)
  const scheme = process.env.NODE_ENV === 'production' ? 'https' : 'http'

  const { refetch } = useQuery<MERCHANT_LAYOUT_SETTING>(
    QUERY_MERCHANT_SETTING,
    {
      variables: { merchant: merchant.id },
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        setData(data)
        setEnabled(getComposerEnabled(data))
      }
    }
  )

  const [updateComposer] = useMutation(UPDATE_MERCHANT_COMPOSER, {
    fetchPolicy: 'no-cache'
  })

  const handleRequestDemo = () => {
    window.open('https://support.slerp.com/composer-demo-request')
  }

  const handleComposerCreate = () => {
    const token = localStorage.getItem('token')
    const slug = data?.merchants_by_pk?.slug

    const url = `${scheme}://${process.env.REACT_APP_COMPOSER_HOST}/api/setup-merchant`

    const postParams = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-JWT-Token': `${token}`,
        'Access-Control-Request-Headers': 'Content-Type,X-JWT-Token'
      },
      body: JSON.stringify({ slug: slug })
    }

    message.destroy()
    message.success('Creating composer space!')

    fetch(url, postParams)
      .then((response) => response.json())
      .then((data) => {
        if (data.response === 201) {
          message.destroy()
          message.success('Composer space created!')

          window.open(data.sso_url)

          updateComposer({
            variables: {
              merchantId: merchant.id,
              setting: {
                composer: {
                  enabled: true,
                  id: slug
                }
              }
            }
          })
            .then((result) => {
              message.destroy()
              message.success('Merchant Composer settings updated!')

              refetch()
            })
            .catch((err) => {
              message.destroy()
              message.error(`Unable to update merchant Composer settings!`)
            })
        } else {
          message.destroy()
          message.error('Unable to create Composer space!')
        }
      })
      .catch((error) => {
        message.destroy()
        message.error('Unable to create Composer space!')
      })
  }

  const actionButton = () => {
    return (
      <>
        {!enabled && (
          <Button
            onClick={handleRequestDemo}
            data-testid='request-demo-btn'
            className='ant-btn flex gap-1 text-l h-full px-4 py-2 items-center bg-[#d092dd] border-[#d092dd] rounded-full'
          >
            <PlusOutlined />
            Request Demo
          </Button>
        )}
        {enabled && !allowComposerSSO(data) && (
          <Button
            onClick={handleComposerCreate}
            data-testid='composer-create-btn'
            className='flex gap-1 text-l h-full px-4 py-2 items-center bg-[#d092dd] border-[#d092dd] rounded-full'
          >
            <PlusOutlined />
            Create your Composer Space
          </Button>
        )}
        {allowComposerSSO(data) && (
          <Button
            onClick={() => handleComposerSSO(data)}
            data-testid='composer-login-btn'
            className='flex gap-1 text-l h-full px-4 py-2 items-center bg-[#d092dd] border-[#d092dd] rounded-full'
          >
            <ComposerIcon />
            Sign in to Composer
          </Button>
        )}
      </>
    )
  }

  useEffect(() => {
    const script = document.createElement('script')

    script.src =
      'https://cdn.tailwindcss.com?plugins=forms,typography,aspect-ratio,line-clamp,container-queries'
    script.async = true

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return (
    <>
      <div className=''>
        <div className='container bg-white mx-auto'>
          <div className='flex justify-center'>
            <div className='w-full text-center'>
              <h1 className='mb-6 mt-12 text-xl font-bold text-[#d092dd] uppercase'>
                Restaurant order management software
              </h1>
              <div className='mb-6 text-4xl font-extrabold text-gray-800'>
                Save time and reduce errors with <br />
                order management software
              </div>
              <div className='mb-6 text-lg text-gray-600'>
                Automate the creation of production schedules, packing slips,
                and delivery exports for all of your orders. Streamline
                restaurant operations with order management software.
              </div>
            </div>
          </div>
        </div>

        <div className='bg-white'>
          <div className='container bg-white mx-auto flex justify-center'>
            {actionButton()}
          </div>
        </div>

        <div className='my-20'>
          <div className='container bg-white mx-auto'>
            <div className='flex justify-center'>
              <div className='w-full md:w-10/12 lg:w-8/12'>
                <div className='relative' style={{ paddingTop: '56.25%' }}>
                  <iframe
                    title='composerVideo'
                    className='absolute left-0 top-0 h-full w-full'
                    src='https://player.vimeo.com/video/662994544?h=534c6c90d2&title=0&byline=0&portrait=0'
                    frameborder='0'
                    allowfullscreen
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className=''>
          <div className='container bg-white mx-auto'>
            <div className='flex flex-wrap justify-between items-center'>
              <div className='w-full md:w-1/2 lg:w-6/12'>
                <div className='mb-12 text-center'>
                  <h2 className='text-3xl font-bold text-gray-800'>
                    Automate order management
                  </h2>
                </div>
                <div className='grid grid-cols-2 gap-2'>
                  <div className=''>
                    <div className='rounded h-full bg-white border p-6 shadow-lg'>
                      <h5 className='text-xl font-semibold text-gray-800'>
                        Save Over 40 Hours Per Week
                      </h5>
                      <p className='text-sm text-gray-600'>
                        By using our order management tool, partners save the
                        equivalent of a full employee's work week—over 40 hours!
                      </p>
                    </div>
                  </div>
                  <div className=''>
                    <div className='rounded h-full bg-white border p-6 shadow-lg'>
                      <h5 className='text-xl font-semibold text-gray-800'>
                        Automate Your Workflow
                      </h5>
                      <p className='text-sm text-gray-600'>
                        Automatically generate production schedules, packing
                        slips, and delivery exports, reducing time spent on
                        manual tasks.
                      </p>
                    </div>
                  </div>
                  <div className=''>
                    <div className='rounded h-full bg-white border p-6 shadow-lg'>
                      <h5 className='text-xl font-semibold text-gray-800'>
                        Say Goodbye to Spreadsheets
                      </h5>
                      <p className='text-sm text-gray-600'>
                        Leave spreadsheets behind and let your team focus on
                        high-value tasks by automating outputs for your kitchen,
                        bakery, and logistics.
                      </p>
                    </div>
                  </div>
                  <div className=''>
                    <div className='rounded h-full bg-white border p-6 shadow-lg'>
                      <h5 className='text-xl font-semibold text-gray-800'>
                        Streamline Your Production
                      </h5>
                      <p className='text-sm text-gray-600'>
                        Gain visibility into your orders and production,
                        ensuring everything is organized and easy to track in
                        one place.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='w-full md:w-5/12'>
                <img
                  className='w-full rounded'
                  src='https://www.slerp.com/wp-content/uploads/2024/11/zig-zag-images.png'
                  alt='order management software mechanics'
                />
              </div>
            </div>
          </div>
        </div>

        <div className=''>
          <div className='container bg-white mx-auto'>
            <div className='flex flex-wrap justify-between items-center'>
              <div className='w-full md:w-1/2 lg:w-6/12'>
                <div className='mb-12 text-center'>
                  <h2 className='text-3xl font-bold text-gray-800'>
                    Full control and flexibility at your fingertips
                  </h2>
                </div>
                <div className='grid grid-cols-2 gap-2'>
                  <div className=''>
                    <div className='rounded h-full bg-white border p-6 shadow-lg'>
                      <h5 className='text-xl font-semibold text-gray-800'>
                        Import All Pre-Orders with Ease
                      </h5>
                      <p className='text-sm text-gray-600'>
                        Bring in pre-orders from Slerp to centralize everything
                        in one system, without additional manual work.
                      </p>
                    </div>
                  </div>
                  <div className=''>
                    <div className='rounded h-full bg-white border p-6 shadow-lg'>
                      <h5 className='text-xl font-semibold text-gray-800'>
                        Gain Real-Time Production Summaries
                      </h5>
                      <p className='text-sm text-gray-600'>
                        Get detailed breakdowns of products, modifiers, and
                        add-ons for complete oversight of your daily production.
                      </p>
                    </div>
                  </div>
                  <div className=''>
                    <div className='rounded h-full bg-white border p-6 shadow-lg'>
                      <h5 className='text-xl font-semibold text-gray-800'>
                        Auto-Generated Packing Slips
                      </h5>
                      <p className='text-sm text-gray-600'>
                        Automatically create packing slips, saving time and
                        reducing errors in the order fulfillment process.
                      </p>
                    </div>
                  </div>
                  <div className=''>
                    <div className='rounded h-full bg-white border p-6 shadow-lg'>
                      <h5 className='text-xl font-semibold text-gray-800'>
                        Bulk Upload Orders from Other Sources
                      </h5>
                      <p className='text-sm text-gray-600'>
                        Seamlessly integrate orders from platforms like Shopify,
                        WooCommerce, and Square with bulk upload options, making
                        the process efficient and accurate.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='w-full md:w-5/12'>
                <img
                  className='w-full rounded-lg'
                  src='https://www.slerp.com/wp-content/uploads/2024/11/zig-zag-images-1.png'
                  alt='order management software dashboard'
                />
              </div>
            </div>
          </div>
        </div>

        <div className='bg-white py-12'>
          <div className='container bg-white mx-auto'>
            <div className='mb-12 text-center'>
              <h2 className='text-3xl font-bold text-gray-800'>
                Order management, no more spreadsheets
              </h2>
            </div>
            <div className='grid grid-cols-3 gap-4'>
              <div className='mb-6 w-full'>
                <div className='flex flex-col justify-center rounded border h-full p-6 text-center shadow-lg'>
                  <img
                    className='mx-auto mb-6'
                    src='https://www.slerp.com/wp-content/uploads/2024/11/page-composer-icon-1.png'
                    alt='an order now option online'
                  />
                  <h5 className='text-xl font-semibold text-gray-800'>
                    Import all Slerp pre-orders
                  </h5>
                  <p className='text-sm text-gray-600'>with a single click.</p>
                </div>
              </div>
              <div className='mb-6 w-full'>
                <div className='flex flex-col justify-center rounded border h-full p-6 text-center shadow-lg'>
                  <img
                    className='mx-auto mb-6'
                    src='https://www.slerp.com/wp-content/uploads/2024/11/page-composer-icon-2.webp'
                    alt='a graph trending upwards'
                  />
                  <h5 className='text-xl font-semibold text-gray-800'>
                    Add orders from other sources
                  </h5>
                  <p className='text-sm text-gray-600'>
                    and add notes, comments and attach PDFs.
                  </p>
                </div>
              </div>
              <div className='mb-6 w-full'>
                <div className='flex flex-col justify-center rounded border h-full p-6 text-center shadow-lg'>
                  <img
                    className='mx-auto mb-6'
                    src='https://www.slerp.com/wp-content/uploads/2024/11/page-composer-icon-3.png'
                    alt='Bulk upload orders'
                  />
                  <h5 className='text-xl font-semibold text-gray-800'>
                    Bulk upload orders
                  </h5>
                  <p className='text-sm text-gray-600'>
                    from external data sources including Shopify, WooCommerce,
                    and Square.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='bg-white py-12'>
          <div className='container bg-white mx-auto'>
            <div className='mb-12 text-center'>
              <h2 className='text-3xl font-bold text-gray-800'>
                Save time & reduce errors
              </h2>
            </div>
            <div className='grid grid-cols-3 gap-4'>
              <div className='mb-6 w-full'>
                <div className='flex flex-col justify-center items-center rounded border h-full p-6 text-center shadow-lg'>
                  <div className='text-4xl font-bold text-gray-800'>40+</div>
                  <p className='text-sm text-gray-600'>
                    hours saved per week on production planning admin and
                    preparing online orders.
                  </p>
                </div>
              </div>
              <div className='mb-6 w-full'>
                <div className='flex flex-col justify-center items-center rounded border h-full p-6 text-center shadow-lg'>
                  <div className='text-4xl font-bold text-gray-800'>100%</div>
                  <p className='text-sm text-gray-600'>
                    elimination of human error.
                  </p>
                </div>
              </div>
              <div className='mb-6 w-full'>
                <div className='flex flex-col justify-center items-center rounded border h-full p-6 text-center shadow-lg'>
                  <div className='text-4xl font-bold text-gray-800'>20</div>
                  <p className='text-sm text-gray-600'>
                    hours saved per week on label printing and planning delivery
                    summaries.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='bg-white'>
          <div className='container bg-white mx-auto flex justify-center'>
            {actionButton()}
          </div>
        </div>
      </div>
    </>
  )
}

export default Composer
