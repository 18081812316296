import React, { useState, useEffect, useRef } from 'react'
import {
  Button,
  Row,
  Col,
  Form,
  Input,
  Typography,
  Table,
  Switch,
  Space
} from 'antd'
import { SearchOutlined, PlusOutlined } from '@ant-design/icons'
import StampCustomerModal from './StampCustomerModal'

import { debounce } from 'lodash'
import ClickableTitle from './../Utils/ClickableTitle'
import { LoyaltyCustomerListItem } from './types'
import BulkAddDrawer from './BulkAddDrawer'
import TableSorter from 'components/Utils/TableSorter'
import { ApolloQueryResult } from '@apollo/client'
import { HandleTableSort, LoyaltyCustomer } from './LoyaltyOverview'
import styled from '@emotion/styled'

type LoyaltyCustomerData = {
  id: string
  email: string
  lastName: string
  firstName: string
  birthdate: string
  insertedAt: string
  merchantId: string
  totalSpent: number
  numberOfStamps: number
  redeemedRewards: number
  allTimeRedeemedRewards: number
}

interface LoyaltyCustomerProps {
  toggleOptIn: () => void
  data: {
    loyaltyCustomersList: LoyaltyCustomerData[]
  }
  customers: LoyaltyCustomer[]
  optInFilter: boolean
  loading: boolean
  customerAggregateRefetch:
    | ((
        variables?:
          | Partial<{
              merchantId: string
              startDate: string
              endDate: string
              storeIds: string[] | []
              email: string
              offset: number
              limit: number
            }>
          | undefined
      ) => Promise<ApolloQueryResult<any>>)
    | undefined
  activeSort: string
  currentPage: number
  setCurrentPage: any
  handleTableSort: ({}: HandleTableSort) =>
    | Promise<ApolloQueryResult<any>>
    | undefined
    | void
  handlePageChangeRefetch: ({
    offset,
    limit
  }: {
    offset: number
    limit: number | undefined
  }) => Promise<ApolloQueryResult<any>> | undefined
}

const { Title } = Typography
const { useForm } = Form

const TopLoyaltyCustomers = ({
  loading,
  data,
  customers,
  optInFilter,
  toggleOptIn,
  customerAggregateRefetch,
  activeSort,
  currentPage,
  setCurrentPage,
  handleTableSort,
  handlePageChangeRefetch
}: LoyaltyCustomerProps) => {
  const [form] = useForm()
  const [stampModalVisible, setStampModalVisible] = useState(false)
  const [modalCustomer, setModalCustomer] =
    useState<LoyaltyCustomerListItem | null>(null)
  const [isDrawerVisible, setIsDrawerVisible] = useState(false)
  const isMounted = useRef(false)

  const triggerCustomerSearchFilter = (searchParam: string | null) => {
    if (searchParam === null) return
    const lowerCased = searchParam.toLowerCase()
    setCurrentPage(1)

    customerAggregateRefetch!({
      email: `%${lowerCased}%`,
      offset: 0
    })
  }
  const debounceSetCustomerSearchFilter = debounce(
    triggerCustomerSearchFilter,
    500
  )

  useEffect(() => {
    return debounceSetCustomerSearchFilter.cancel()
  }, [])

  useEffect(() => {
    if (isMounted.current) {
      triggerCustomerSearchFilter(null)
    } else {
      isMounted.current = true
    }
  }, [activeSort])

  const dataColumns = [
    {
      title: 'Customer',
      dataIndex: 'firstName',
      key: 'name',
      render: (_firstName: string, record: LoyaltyCustomerListItem) => {
        return (
          <>
            <Button
              icon={<PlusOutlined />}
              className='_mr-8'
              onClick={() => {
                setStampModalVisible(true)
                setModalCustomer(record)
              }}
            ></Button>
            <span>{`${record.firstName} ${record.lastName}`}</span>
          </>
        )
      }
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (email: string) => {
        return <span>{`${email}`}</span>
      }
    },
    {
      title: (
        <Row className='_mb-0 custom-sorter' align='middle' gutter={8}>
          <ClickableTitle
            onClick={() =>
              handleTableSort({
                sortBy: 'stamps',
                sortDirection: 'desc',
                id: 'stamps-desc'
              })
            }
            title='Number of stamps'
            type='descending'
          />
          <Col>
            <Space direction='vertical' size={0} className='_mb-2'>
              <div className='_center-vertical'>
                <TableSorter
                  onClick={() =>
                    handleTableSort({
                      sortBy: 'stamps',
                      sortDirection: 'asc',
                      id: 'stamps-asc'
                    })
                  }
                  type='asc'
                  isActive={activeSort === 'stamps-asc'}
                />
              </div>
              <div className='_center-vertical'>
                <TableSorter
                  onClick={() =>
                    handleTableSort({
                      sortBy: 'stamps',
                      sortDirection: 'desc',
                      id: 'stamps-desc'
                    })
                  }
                  type='desc'
                  isActive={activeSort === 'stamps-desc'}
                />
              </div>
            </Space>
          </Col>
        </Row>
      ),
      dataIndex: 'numberOfStamps',
      key: 'stampsCollected',
      render: (numberOfStamps: number) => <span>{numberOfStamps}</span>
    },
    {
      title: (
        <Row className='_mb-0 custom-sorter' align='middle' gutter={8}>
          <ClickableTitle
            onClick={() =>
              handleTableSort({
                sortBy: 'customer_rewards',
                sortDirection: 'desc',
                id: 'rewards-redeemed-desc'
              })
            }
            title='Rewards redeemed'
            type='descending'
          />
          <Col>
            <Space direction='vertical' size={0} className='_mb-2'>
              <div className='_center-vertical'>
                <TableSorter
                  onClick={() =>
                    handleTableSort({
                      sortBy: 'customer_rewards',
                      sortDirection: 'asc',
                      id: 'rewards-redeemed-asc'
                    })
                  }
                  type='asc'
                  isActive={activeSort === 'rewards-redeemed-asc'}
                />
              </div>
              <div className='_center-vertical'>
                <TableSorter
                  onClick={() =>
                    handleTableSort({
                      sortBy: 'customer_rewards',
                      sortDirection: 'desc',
                      id: 'rewards-redeemed-desc'
                    })
                  }
                  type='desc'
                  isActive={activeSort === 'rewards-redeemed-desc'}
                />
              </div>
            </Space>
          </Col>
        </Row>
      ),
      dataIndex: 'redeemedRewards',
      key: 'subtotal',
      render: (text: string) => <span>{text}</span>
    },
    {
      title: (
        <Row className='_mb-0 custom-sorter' align='middle' gutter={8}>
          <ClickableTitle
            onClick={() =>
              handleTableSort({
                sortBy: 'customer_all_time_rewards',
                sortDirection: 'desc',
                id: 'all-time-rewards-redeemed-desc'
              })
            }
            title='Rewards redeemed (all time)'
            type='descending'
          />
          <Col>
            <Space direction='vertical' size={0} className='_mb-2'>
              <div className='_center-vertical'>
                <TableSorter
                  onClick={() =>
                    handleTableSort({
                      sortBy: 'customer_all_time_rewards',
                      sortDirection: 'asc',
                      id: 'all-time-rewards-redeemed-asc'
                    })
                  }
                  type='asc'
                  isActive={activeSort === 'all-time-rewards-redeemed-asc'}
                />
              </div>
              <div className='_center-vertical'>
                <TableSorter
                  onClick={() =>
                    handleTableSort({
                      sortBy: 'customer_all_time_rewards',
                      sortDirection: 'desc',
                      id: 'all-time-rewards-redeemed-desc'
                    })
                  }
                  type='desc'
                  isActive={activeSort === 'all-time-rewards-redeemed-desc'}
                />
              </div>
            </Space>
          </Col>
        </Row>
      ),
      dataIndex: 'allTimeRedeemedRewards',
      key: 'redeemedRewards',
      render: (text: string) => <span>{text}</span>
    },
    {
      title: (
        <Row className='_mb-0 custom-sorter' align='middle' gutter={8}>
          <ClickableTitle
            onClick={() =>
              handleTableSort({
                sortBy: 'orders',
                sortDirection: 'desc',
                id: 'total-spent-desc'
              })
            }
            title='Total spent'
            type='descending'
          />
          <Col>
            <Space direction='vertical' size={0} className='_mb-2'>
              <div className='_center-vertical'>
                <TableSorter
                  onClick={() =>
                    handleTableSort({
                      sortBy: 'orders',
                      sortDirection: 'asc',
                      id: 'total-spent-asc'
                    })
                  }
                  type='asc'
                  isActive={activeSort === 'total-spent-asc'}
                />
              </div>
              <div className='_center-vertical'>
                <TableSorter
                  onClick={() =>
                    handleTableSort({
                      sortBy: 'orders',
                      sortDirection: 'desc',
                      id: 'total-spent-desc'
                    })
                  }
                  type='desc'
                  isActive={activeSort === 'total-spent-desc'}
                />
              </div>
            </Space>
          </Col>
        </Row>
      ),
      dataIndex: 'totalSpent',
      key: 'total',
      render: (totalSpent: number) => <span>£{totalSpent || 0.0}</span>
    }
  ]

  const handleDrawerVisibility = (visible: boolean) =>
    setIsDrawerVisible(visible)

  return (
    <>
      <Form form={form} className='_mb-24'>
        <BulkAddDrawer
          onClose={() => handleDrawerVisibility(false)}
          visible={isDrawerVisible}
        />
        <Row justify='space-between' className='_mt-32'>
          <Col>
            <Row align='middle' className='_mb-0'>
              <Title level={3} className='loyalty-customer-title _mb-0 _pr-24'>
                Top loyalty customers
              </Title>
              <span className='_pl-24'>
                Show only customers who've opted in
              </span>
              <Switch
                checked={optInFilter}
                onChange={toggleOptIn}
                className='_ml-12'
              />
            </Row>
          </Col>
          <Row className='_mb-0' align='middle'>
            <Col className='orders-form-item'>
              <Form.Item name='query' className='control _mb-0'>
                <StyledInput
                  prefix={<SearchOutlined className='site-form-item-icon' />}
                  placeholder='Search by email'
                  onChange={(e: any) =>
                    debounceSetCustomerSearchFilter(e.target.value)
                  }
                />
              </Form.Item>
            </Col>
            <Col>
              <Button onClick={() => setIsDrawerVisible(true)}>
                Bulk Actions
              </Button>
            </Col>
          </Row>
        </Row>
      </Form>
      <Table
        data-testid='topLoyaltyCustomersTable'
        dataSource={data?.loyaltyCustomersList}
        columns={dataColumns}
        loading={loading}
        pagination={{
          defaultPageSize: 100,
          position: ['bottomCenter'],
          showSizeChanger: true,
          current: currentPage,
          total: (customers || []).length,
          onChange: (page, pageSize) => {
            const offset = (pageSize || 100) * page - (pageSize || 100)
            setCurrentPage(page)

            handlePageChangeRefetch({
              offset: offset,
              limit: pageSize
            })
          }
        }}
      />
      <StampCustomerModal
        customer={modalCustomer}
        visible={stampModalVisible}
        onClose={() => {
          setStampModalVisible(false)
          setModalCustomer(null)
        }}
      />
    </>
  )
}

const StyledInput = styled(Input)`
  width: 280px;
`

export default TopLoyaltyCustomers
