import React, { useState } from 'react'
import { Col, Divider, Form, Steps, Row, message } from 'antd'
import { Redirect } from 'react-router-dom'
import { useSession } from '@slerp/accounts'
import { useMutation } from '@apollo/client'
import { CREATE_LOCATION, RECALCULATE_STORE_TIMER } from '../actions'
import DetailsForm from './DetailsForm'
import StoreUsersForm from './StoreUsersForm'
import { DayOfWeek } from '../GeneralSettings/SplitShifts/types'
import { UPDATE_STORE_SPLIT_HOURS } from '../LocationQueries'
import Loading from './../../Utils/Loading'

const DAYS: Array<DayOfWeek> = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday'
]

const LocationForm = () => {
  const { merchant } = useSession()
  const defaultFormState = {
    detailsForm: {
      storeName: '',
      email: '',
      address: {
        validateTrigger: true,
        formattedAddress: undefined,
        line1: undefined,
        line2: undefined,
        city: undefined,
        country: undefined,
        postalCode: undefined,
        lat: 0,
        lng: 0
      },
      contactNumber: '',
      courierInstructions: ''
    },
    inventoryForm: {
      variants: [],
      modifiers: []
    },
    mobileAppSettingsForm: {
      onDemandOrders: false,
      scheduledOrders: false,
      reminderType: 'courier',
      deliveryMinutes: 15,
      pickupMinutes: 60,
      quietActivityPrepTime: 5,
      moderateActivityPrepTime: 15,
      busyActivityPrepTime: 30
    },
    storeUsersForm: {
      users: [
        {
          firstName: '',
          lastName: '',
          email: '',
          password: ''
        }
      ]
    }
  }
  const [currentStep, setCurrentStep] = useState(0)
  const [formState, setFormState] = useState(defaultFormState)
  const [shouldRedirect, setShouldRedirect] = useState(false)

  const [createLocation, { loading: loadingCreateLocation }] = useMutation(
    CREATE_LOCATION,
    {
      fetchPolicy: 'no-cache'
    }
  )

  const [recalculateStoreTimer, { loading: loadingRecalculateStoreTimer }] =
    useMutation(RECALCULATE_STORE_TIMER, {
      fetchPolicy: 'no-cache'
    })

  const [updateStoreSplitHours, { loading: loadingStoreSplitHours }] =
    useMutation(UPDATE_STORE_SPLIT_HOURS, {
      fetchPolicy: 'no-cache'
    })

  const steps = [
    {
      stepIndex: 0,
      formName: 'detailsForm',
      title: 'Location Details',
      renderComponent: (formData: any) => <DetailsForm formData={formData} />,
      tooltip: ''
    },
    {
      stepIndex: 2,
      formName: 'storeUsersForm',
      title: 'Location Users',
      renderComponent: (formData: any) => (
        <StoreUsersForm formData={formData} handlePrevForm={handlePrevForm} />
      )
    }
  ]

  const handlePrevForm = () => setCurrentStep(currentStep - 1)

  const handleFormSubmit = (formName: string, info: any) => {
    let updatedFormState = {
      ...formState,
      [formName]: info.values
    }

    const additionalFee = {
      isActive: true,
      name: 'Service Fee',
      amount: '1',
      amountType: 'fixed',
      fulfillmentTypes: {
        delivery: true,
        pickup: false,
        tableOrdering: false
      }
    }

    setFormState(updatedFormState)

    if (currentStep === 1) {
      const { detailsForm, storeUsersForm, mobileAppSettingsForm } =
        updatedFormState

      message.loading('Creating new location..', 0)

      return createLocation({
        variables: {
          merchant_id: merchant.id,
          name: detailsForm.storeName,
          email: detailsForm.email,
          pickup_notes: detailsForm.courierInstructions,
          settings: {
            quietPreparationTime: mobileAppSettingsForm.quietActivityPrepTime,
            moderatePreparationTime:
              mobileAppSettingsForm.moderateActivityPrepTime,
            busyPreparationTime: mobileAppSettingsForm.busyActivityPrepTime,
            additionalFee: additionalFee
          },
          prep_reminders: {
            asapEnabled: mobileAppSettingsForm.onDemandOrders,
            scheduledEnabled: mobileAppSettingsForm.scheduledOrders,
            deliveryType:
              mobileAppSettingsForm.reminderType === 'courier'
                ? 'courier'
                : null,
            pickupMinutes: mobileAppSettingsForm.pickupMinutes,
            deliveryMinutes: mobileAppSettingsForm.deliveryMinutes
          },
          address: {
            contactNum: `+44${detailsForm.contactNumber}`,
            line_1: detailsForm.address_line_1,
            line_2: detailsForm.address_line_2,
            city: detailsForm.address_city,
            country: detailsForm.address_country,
            zip: detailsForm.address_postcode.toUpperCase(),
            lat: detailsForm.address_lat,
            lng: detailsForm.address_lng
          },
          users: storeUsersForm.users.map((user) => ({
            firstname: user.firstName,
            lastname: user.lastName,
            email: user.email,
            password: user.password,
            role: 'member'
          }))
        }
      })
        .then((res) => {
          const { id } = res.data.createStore

          const dailyShifts = DAYS.map((day) => ({
            day_of_week: day,
            start_time: '11:00:00',
            seconds_length: 43200,
            enabled: true,
            special_date: null,
            closed: false
          }))

          updateStoreSplitHours({
            variables: {
              id: id,
              splitHours: dailyShifts
            }
          })
            .then(() => {
              recalculateStoreTimer({
                variables: {
                  storeId: id
                }
              })
                .then(() => {
                  message.destroy()
                  message.success('New location created!', 1)
                  setShouldRedirect(true)
                })
                .catch((error: Error) => {
                  message.destroy()
                  message.error(
                    `Unable to recalculate store timer due to ${error.message}`,
                    5
                  )
                })
            })
            .catch((error: Error) => {
              message.destroy()
              message.error(
                `Unable to create split shifts due to ${error.message}`,
                5
              )
            })
            .catch((error: Error) => {
              message.destroy()
              message.error(
                `Unable to create pre order settings due to ${error.message}`,
                5
              )
            })
        })
        .catch((error: Error) => {
          message.destroy()
          message.error(`Unable to create location due to ${error.message}`, 5)
        })
    }

    setCurrentStep(steps[currentStep].stepIndex + 1)
  }

  const renderCurrentForm = () => {
    const currentForm = steps[currentStep]
    const currentFormState = Object(formState)[currentForm.formName]
    return currentForm.renderComponent(currentFormState)
  }

  if (
    loadingCreateLocation ||
    loadingStoreSplitHours ||
    loadingRecalculateStoreTimer
  )
    return <Loading />

  return (
    <>
      <Row data-testid='new-location-form'>
        <Col span={24}>
          <Steps
            current={currentStep}
            onChange={(step) => setCurrentStep(step)}
            type='navigation'
          >
            {steps.map((step, i) => (
              <Steps.Step
                key={`${i}${step.formName}`}
                disabled={i > currentStep}
                title={<span className='_pb-24'>{step.title}</span>}
              />
            ))}
          </Steps>
        </Col>
        <Row gutter={40} className='_w-100 _mt-40'>
          <Col span={16} className='_ml-24'>
            <Form.Provider onFormFinish={handleFormSubmit}>
              {renderCurrentForm()}
            </Form.Provider>
          </Col>
          <Col>
            <Divider type='vertical' />
          </Col>
          <Col span={8}>{steps[currentStep].tooltip}</Col>
        </Row>
      </Row>
      {shouldRedirect && <Redirect to='/locations' />}
    </>
  )
}

export default LocationForm
