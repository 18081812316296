import React, { useEffect, useState, useMemo } from 'react'
import { useSession, isAdmin, isManager } from '@slerp/accounts'
import {
  Input,
  Form,
  Button,
  Divider,
  message,
  PageHeader,
  Tooltip,
  Col,
  InputNumber,
  Row,
  Typography
} from 'antd'
import isEmpty from 'lodash/isEmpty'
import FulfillmentTypeToggle from './General/FulfillmentTypeToggle'
import DeliveryPricingBands from '../DeliveryPricingBands'
import TimeSlots, {
  delivery_timeslots,
  own_drivers_delivery_timeslots
} from './General/TimeSlots'
import FulfillmentTimesType from './General/FulfillmentTimesType'
import CourierType from './Delivery/CourierType'
import DeliveryFee from './Delivery/DeliveryFee'
import DeliveryZone from './Delivery/DeliveryZone'
import SplitShifts from './SplitShifts'
import { Schedules, Store, Merchant } from '@slerp/controls'
import { getStorePreorderSettings, initialSchedules } from './utils'
import { SanitizeShifts, WithConflictingDailyShifts } from './SplitShifts/utils'
import { RECALCULATE_STORE_TIMER } from 'components/Locations/actions'
import {
  UPDATE_DELIVERY_SETTINGS,
  CREATE_DELIVERY_SETTINGS,
  UPDATE_PREORDER_DELIVERY_SPLIT_HOURS,
  UPDATE_PREORDER_SETTINGS
} from './actions'
import { useApolloClient, useMutation } from '@apollo/client'
import { uuid } from 'uuidv4'
import OrderLimit from './General/OrderLimit'
import { Store as FormStore } from 'rc-field-form/lib/interface'
import SetupNoticeModal from './Cutoffs/SetupNoticeModal'
import ErrorMessage from 'components/Utils/ErrorMessage'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { warningModal, FeeTypes, getVehicleMinDistance } from './../utils'
import { vatPercentageFieldRule } from './../rules'
import { SplitHour } from './SplitShifts/types'
import useGetDeliveryPricingBands from 'components/CustomHooks/DeliveryPricingbands/useGetDeliveryPricingBands'
import useGetDiscounts from 'components/CustomHooks/Discounts/useGetDiscounts'
import useUnpublishDiscounts from 'components/CustomHooks/Discounts/useUnpublishDiscounts'
import useUpsertDeliveryPricingBands from 'components/CustomHooks/DeliveryPricingbands/useUpsertDeliveryPricingBands'
import { DeliveryPricingBand } from '../DeliveryPricingBands/types'
import {
  FormatPricingBandsForPersistence,
  ValidateDeliveryPricingBands
} from '../DeliveryPricingBands/utils'
import { Discount } from 'components/CustomHooks/Discounts/types'
import {
  isActive as isDiscountActive,
  isScheduled as isDiscountScheduled,
  descriptionText as discountDescriptionText
} from 'components/Discounts/Helpers'
import { Spinner, CustomModal } from 'components/Widgets'
import cloneDeep from 'lodash/cloneDeep'
import { DEFAULT_DELIVERY_RADIUS, DEFAULT_DELIVERY_TIME } from '../utils'
import { onBlurScrollNumber, onFocusScrollNumber } from 'components/Utils/price'

const { Text } = Typography

const DeliveryForm = ({
  merchant,
  store: originalStore,
  invalidCutoffs,
  refetch
}: {
  merchant: Merchant
  store: Store
  invalidCutoffs: boolean
  refetch: () => {}
}) => {
  const settings = getStorePreorderSettings(originalStore)
  const { user } = useSession()
  const canManage = isAdmin(user) || isManager(user)
  const [showModal, setShowModal] = useState(false)
  const [
    sourcePricingBands,
    { loading: fetchingPricingBands, refetch: refetchPricingBands }
  ] = useGetDeliveryPricingBands({
    storeId: originalStore.id,
    orderType: 'PREORDER'
  })
  const [discounts, { refetch: refetchDiscounts }] = useGetDiscounts({
    merchantId: merchant?.id
  })
  const [unpublishDiscounts] = useUnpublishDiscounts()
  const [upsertDeliveryPricingBands] = useUpsertDeliveryPricingBands()

  const activeAutomaticDiscounts = useMemo(
    () =>
      discounts.filter((discount: Discount) => {
        const { store_ids, target, trigger } = discount
        const isAllStores = (store_ids || []).length === 0
        const storeCondition =
          (store_ids && (store_ids || []).includes(originalStore.id)) ||
          isAllStores
        return (
          storeCondition &&
          (isDiscountActive(discount) || isDiscountScheduled(discount)) &&
          target === 'delivery_fee' &&
          trigger === 'automatic'
        )
      }),
    [discounts, originalStore.id]
  )

  const [pricingBands, setPricingBands] = useState<DeliveryPricingBand[]>([])
  const [store, setStore] = useState<Store>(cloneDeep(originalStore))
  const [enabled, setEnabled] = useState(settings?.delivery_enabled || false)
  const [courier, setCourier] = useState<'partner' | 'other'>(
    settings
      ? settings.delivery_courier_partner
        ? 'partner'
        : 'other'
      : 'partner'
  )
  const [feeType, setFeeType] = useState<FeeTypes>('')
  const [deliveryTimes, setDeliveryTimes] = useState<
    'opening_hours' | 'custom_hours'
  >(settings?.delivery_times || 'custom_hours')

  const getDeliveryTimeslot = () => {
    if (settings?.fixed_delivery_times_enabled) return 'fixed'
    const deliveryInterval = settings?.delivery_interval || 60
    const usingSCP = courier === 'partner'
    // in the event like a merchant is under its own drivers and selected timeslot below
    // 30 minutes, since SCP only serves 30 and 1 hour, we make sure we still catch it and default
    // it to 1 hour
    return usingSCP && deliveryInterval < 30 ? 60 : deliveryInterval
  }
  const [timeslot, setTimeslot] = useState<string | number>(
    getDeliveryTimeslot()
  )
  const [schedules, setSchedules] = useState<Schedules>(
    settings && !isEmpty(settings.delivery_schedule)
      ? settings.delivery_schedule
      : initialSchedules
  )
  const [splitHours, setSplitHours] = useState<SplitHour[]>(
    SanitizeShifts(
      (settings?.split_hours || []).filter(
        (shift: SplitHour) => shift.enabled && isEmpty(shift.special_date)
      )
    )
  )

  const [form] = Form.useForm()
  const [updateStoreSettings] = useMutation(UPDATE_PREORDER_SETTINGS)
  const [updateStore, { loading, error }] = useMutation(
    settings ? UPDATE_DELIVERY_SETTINGS : CREATE_DELIVERY_SETTINGS,
    { fetchPolicy: 'no-cache', awaitRefetchQueries: true }
  )
  const [recalculateStoreTimer] = useMutation(RECALCULATE_STORE_TIMER, {
    fetchPolicy: 'no-cache'
  })
  const [updatePreorderDeliverySplitHours] = useMutation(
    UPDATE_PREORDER_DELIVERY_SPLIT_HOURS,
    {
      fetchPolicy: 'no-cache'
    }
  )
  const client = useApolloClient()
  const merchantPriceMatrix = merchant.price_matrices.find((pm) => pm.global)
  const storePriceMatrix = merchant.price_matrices.find((pm) => {
    const storeSlugs = pm.stores.map((store) => store.slug)

    if (pm.global === false && storeSlugs.includes(store.slug)) {
      return pm
    }
  })
  const usingCustomHours = deliveryTimes === 'custom_hours'

  const ModalContents = () => {
    return (
      <>
        <Row className='_mb-6'>
          <Col>
            The following delivery related discount will now be disabled, as you
            have enabled Delivery pricing by basket value for this location.
          </Col>
        </Row>
        {activeAutomaticDiscounts.map((discount: Discount) => (
          <Row className='_mb-0'>
            <Col>
              <Text strong>{`(${discount.code}) ${discountDescriptionText(
                discount
              )}`}</Text>
            </Col>
          </Row>
        ))}
      </>
    )
  }

  const submit = () => {
    if (!!activeAutomaticDiscounts.length && !!pricingBands.length) {
      CustomModal({
        type: 'confirm',
        content: <ModalContents />,
        okCancel: true,
        okText: 'Proceed',
        onOk: () => form.submit()
      })
    } else {
      form.submit()
    }
  }

  const resetForm = () => {
    setStore(cloneDeep(originalStore))
    setPricingBands(sourcePricingBands)

    setTimeslot(getDeliveryTimeslot())
    setDeliveryTimes(settings?.delivery_times || 'custom_hours')

    setSplitHours(
      SanitizeShifts(
        (settings?.split_hours || []).filter(
          (shift: SplitHour) => shift.enabled && isEmpty(shift.special_date)
        )
      )
    )

    form.resetFields()
  }

  const updateDeliveryAreaSettings = (values: FormStore) => {
    const {
      delivery_radius,
      delivery_time,
      delivery_zone_type,
      delivery_area,
      delivery_area_touched,
      kml_upload
    } = values

    const isCustomDeliveryArea = !!(
      delivery_area_touched && delivery_area?.length
    )

    const commonSettings = {
      customDeliveryArea: isCustomDeliveryArea,
      kmlUpload: !!(kml_upload && isCustomDeliveryArea)
    }

    const deliveryAreaSetting = isCustomDeliveryArea
      ? { delivery_area, ...commonSettings }
      : delivery_zone_type === 'distance'
      ? { delivery_radius, ...commonSettings }
      : { delivery_time, ...commonSettings }

    return updateStoreSettings({
      variables: {
        storeId: store.id,
        preOrderSettings: {
          ...deliveryAreaSetting
        }
      }
    })
  }

  const onFinish = async (values: FormStore) => {
    if (form.validateFields()) {
      message.loading('Updating... Please wait.')

      await updateDeliveryAreaSettings(values)

      updateStore({
        variables: {
          ...(settings ? {} : { id: uuid() }),
          store_id: store.id,
          delivery_enabled: enabled,
          delivery_courier_partner: courier === 'partner',
          fixed_courier_fee_enabled: feeType === 'custom',
          pre_order_rate_cards_enabled: feeType === 'rate_card',
          custom_fixed_fee:
            values.custom_fixed_fee ||
            (settings ? settings.custom_fixed_fee : 0),
          delivery_times: deliveryTimes,
          delivery_schedule:
            deliveryTimes === 'custom_hours'
              ? schedules
              : settings?.delivery_schedule,
          fixed_delivery_times_enabled: timeslot === 'fixed',
          delivery_interval:
            timeslot === 'fixed' ? settings?.delivery_interval || 60 : timeslot,
          delivery_zone: 'radius',
          delivery_timeslots_order_limit:
            values.delivery_timeslots_order_limit || 0,
          fixed_delivery_times_order_limit:
            values.delivery_timeslots_order_limit || 0,
          settings: {
            ...store.settings,
            pre_order_delivery: enabled,
            prep_mode:
              !!values.custom_delivery_area && values.busy_delivery_area_enabled
                ? 'busy'
                : store.settings.prep_mode
          },
          preorder_delivery_minimum_order_value:
            values.preorder_delivery_minimum_order_value,
          courier_fee_vat_percentage: !!values.courier_fee_vat_percentage
            ? values.courier_fee_vat_percentage
            : 0
        }
      })
        .then((result) => {
          recalculateStoreTimer({
            variables: {
              storeId: store.id
            }
          })

          if (usingCustomHours) {
            updatePreorderDeliverySplitHours({
              variables: {
                id: store.id,
                splitHours
              }
            }).finally(() => refetch())
          }
        })
        .then(() => {
          const tasks = []
          if (!!activeAutomaticDiscounts.length && !!pricingBands.length) {
            const discountIds = activeAutomaticDiscounts.map(
              ({ id }: Discount) => id
            )
            tasks.push(
              unpublishDiscounts({
                merchantId: merchant.id,
                discountIds,
                successCallback: refetchDiscounts
              })
            )
          }

          upsertDeliveryPricingBands({
            deliveryPricingBands: FormatPricingBandsForPersistence({
              pricingBands: [...pricingBands]
            }),
            storeId: store.id,
            orderType: 'PREORDER'
          })

          Promise.allSettled(tasks)
        })
        .finally(() => {
          refetch()
          refetchPricingBands()
          message.destroy()
          message.success('Updated pre-order delivery settings', 2)
        })
        .catch((error) =>
          message.error(
            `Unable to update your pre-order delivery settings due to: ${error}`,
            5
          )
        )
    }
  }

  useEffect(() => {
    if (settings) {
      if (settings.pre_order_rate_cards_enabled === true) {
        setFeeType('rate_card')
      } else if (settings.fixed_courier_fee_enabled === true) {
        setFeeType('custom')
      } else {
        setFeeType('quoted')
      }
    }
  }, [settings])

  useEffect(() => {
    // do not display any warning if saved fee type is not rate_card
    if (!settings?.pre_order_rate_cards_enabled) return

    if (settings?.pre_order_rate_cards_enabled && feeType === 'quoted')
      return warningModal({
        warningType: 'quoted',
        onOk: () => setFeeType('quoted'),
        onCancel: () => setFeeType('rate_card')
      })
    if (settings?.pre_order_rate_cards_enabled && feeType === 'custom')
      return warningModal({
        warningType: 'custom',
        onOk: () => setFeeType('custom'),
        onCancel: () => setFeeType('rate_card')
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feeType])

  useEffect(() => {
    // do not display anything if courier service is "my own drivers"
    if (!settings?.delivery_courier_partner) return
    if (courier === 'partner') return

    // guard clause, warning will only show up if partner has SCP set in their config and is switching away from it
    if (settings?.delivery_courier_partner && courier === 'other')
      setFeeType('custom')

    return warningModal({
      warningType: 'courier',
      onOk: () => {
        setCourier('other')
        setFeeType('custom')

        // deliberately sets the custom fee field to null
        if (
          settings.custom_fixed_fee <= 0 ||
          !settings.custom_fixed_fee ||
          form.getFieldValue('custom_fixed_fee')
        )
          return form.setFieldsValue({
            custom_fixed_fee: null
          })
      },
      onCancel: () => {
        setCourier('partner')
        setFeeType('rate_card')
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courier])

  useEffect(() => {
    const timeslot = getDeliveryTimeslot()
    form.setFieldsValue({ delivery_interval: timeslot })
    setTimeslot(timeslot)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courier])

  useEffect(() => {
    if (deliveryTimes === 'opening_hours') {
      setSplitHours(
        SanitizeShifts(
          (settings?.split_hours || []).filter(
            (shift: SplitHour) => shift.enabled && isEmpty(shift.special_date)
          )
        )
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deliveryTimes])

  useEffect(() => {
    if (!fetchingPricingBands && !!sourcePricingBands.length) {
      setPricingBands(sourcePricingBands)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourcePricingBands])

  // show warning modal if there is no cut-off settings data and user enables delivery
  if (!settings && enabled) {
    setShowModal(true)
    setEnabled(false)
  }

  const isSplitShiftsInvalid = () => {
    return WithConflictingDailyShifts(splitHours)
  }

  const DisallowSave = () => {
    const invalidDeliveryPricingBands = !!pricingBands.length
      ? ValidateDeliveryPricingBands({ pricingBands })
      : false

    return !canManage || invalidDeliveryPricingBands || isSplitShiftsInvalid()
  }

  const maxDistance = useMemo(() => {
    return courier === 'partner'
      ? getVehicleMinDistance(merchant.vehicles)
      : null
  }, [merchant.vehicles, courier])

  const maxPreorderDistance = useMemo(() => {
    return courier === 'partner'
      ? getVehicleMinDistance(merchant.pre_order_vehicles)
      : null
  }, [merchant.pre_order_vehicles, courier])

  return (
    <Form
      colon={false}
      layout='vertical'
      onFinish={onFinish}
      form={form}
      initialValues={{
        delivery_area_touched:
          store.pre_order_settings?.[0]?.delivery_area_setting
            ?.custom_delivery_area,
        kml_upload:
          !!store.pre_order_settings?.[0]?.delivery_area_setting?.kml_upload,
        delivery_area:
          store.pre_order_settings?.[0]?.delivery_area_setting?.delivery_area ||
          [],
        delivery_zone_type: store.pre_order_settings?.[0]?.delivery_radius
          ? 'distance'
          : 'time',
        delivery_radius:
          store.pre_order_settings?.[0]?.delivery_radius ||
          DEFAULT_DELIVERY_RADIUS,
        delivery_time:
          store.pre_order_settings?.[0]?.delivery_time || DEFAULT_DELIVERY_TIME,
        preorder_delivery_minimum_order_value:
          store.preorder_delivery_minimum_order_value || 0,
        custom_fixed_fee: settings?.custom_fixed_fee,
        delivery_timeslots_order_limit:
          settings?.delivery_timeslots_order_limit || 0,
        fixed_delivery_times_order_limit:
          settings?.fixed_delivery_times_order_limit || 0
      }}
    >
      {error && (
        <ErrorMessage
          title='Delivery settings could not be updated'
          subheading={`Error: ${error}`}
        />
      )}
      <PageHeader title='Delivery' className='settings-title' />

      {showModal && (
        <SetupNoticeModal
          title='Please setup your cutoff times before enabling delivery'
          visible={showModal}
          onClose={() => {
            setShowModal(false)
            client.resetStore()
          }}
        />
      )}

      <FulfillmentTypeToggle
        type='delivery'
        onClick={
          (invalidCutoffs || !settings) && !enabled
            ? () => setShowModal(true)
            : null
        }
        handleChange={setEnabled}
        value={enabled}
        disabled={!canManage}
      />

      <div
        style={{
          ...(!enabled && {
            position: 'absolute',
            zIndex: -99999,
            visibility: 'hidden'
          })
        }}
        data-testid='preorder-delivery-settings-form'
      >
        <Row gutter={32} className='_mb-0'>
          <Col span='auto'>
            <Form.Item label='Courier service:' className='_mb-40'>
              <CourierType
                value={courier}
                handleChange={(value) => {
                  setCourier(value)
                  if (isEmpty(storePriceMatrix) && isEmpty(merchantPriceMatrix))
                    return setFeeType('quoted')
                  if (value === 'partner') return setFeeType('rate_card')
                  if (value === 'other') return setFeeType('custom')
                }}
                disabled={!canManage}
              />
            </Form.Item>
          </Col>
          {courier === 'other' && (
            <Col span='6'>
              <Form.Item
                name='courier_fee_vat_percentage'
                label={<>Courier fee VAT (%):</>}
                className='_mb-40'
                initialValue={settings?.courier_fee_vat_percentage || 0}
                required
                rules={vatPercentageFieldRule}
              >
                <InputNumber
                  placeholder='0'
                  className='input-percentage'
                  type='number'
                  max={100}
                  min={0}
                  data-testid='preorders-courier-fee-vat-percentage'
                  disabled={!canManage}
                  onFocus={onFocusScrollNumber}
                  onBlur={onBlurScrollNumber}
                />
              </Form.Item>
            </Col>
          )}
        </Row>

        <Form.Item
          label={
            <>
              Delivery pricing:
              <Tooltip
                placement='right'
                title={
                  <>
                    <a
                      href='https://support.slerp.com/knowledge/delivery-costs'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      Delivery Costs
                    </a>
                  </>
                }
                className='_ml-8'
              >
                <div data-testid='tooltip-delivery-pricing'>
                  <QuestionCircleOutlined className='_mt-2' />
                </div>
              </Tooltip>
            </>
          }
          className='_mb-40'
        >
          <DeliveryFee
            storeSlug={store.slug}
            value={feeType}
            handleChange={setFeeType}
            courier={courier}
            disabled={!canManage}
            priceMatrix={
              !!storePriceMatrix ? storePriceMatrix : merchantPriceMatrix
            }
          />
        </Form.Item>

        <Form.Item
          label={
            <>
              Delivery pricing by basket value:
              <Tooltip
                placement='right'
                title={
                  <>
                    Delivery pricing by basket value is a tiered reduction on
                    delivery fees based on the net value of items being ordered.
                    They cannot be used with automatic delivery discounts but do
                    work with product-based discounts and loyalty rewards.
                  </>
                }
                className='_ml-8'
              >
                <QuestionCircleOutlined className='_mt-2' />
              </Tooltip>
            </>
          }
        >
          {fetchingPricingBands && (
            <Spinner message='Fetching delivery pricing bands...' />
          )}
          {!fetchingPricingBands && (
            <DeliveryPricingBands
              pricingBands={pricingBands}
              updateHandler={setPricingBands}
            />
          )}
        </Form.Item>

        <Form.Item label='Delivery times:' className='_mb-40'>
          <FulfillmentTimesType
            value={deliveryTimes}
            handleChange={(value) => setDeliveryTimes(value)}
          />
        </Form.Item>
        {usingCustomHours && (
          <SplitShifts
            settingsId={settings?.id}
            splitHours={splitHours}
            setSplitHours={setSplitHours}
            disabled={false}
          />
        )}
        <TimeSlots
          formName='delivery_interval'
          value={timeslot}
          handleChange={(value) => setTimeslot(value)}
          timeslots={
            courier === 'partner'
              ? delivery_timeslots
              : own_drivers_delivery_timeslots
          }
          disabled={!canManage}
        />

        <DeliveryZone
          maxDistance={maxDistance}
          maxPreorderDistance={maxPreorderDistance}
          store={store}
          form={form}
          disabled={!canManage}
          useStorePreOrderSettings
        />
        <br />
        <OrderLimit
          inputName='delivery_timeslots_order_limit'
          formRef={form}
          disabled={!canManage}
        />
        <Form.Item
          className='_mt-32'
          name='preorder_delivery_minimum_order_value'
          label={
            <>
              Minimum order value
              <Tooltip
                placement='right'
                title={
                  <>
                    Set a minimum amount your customers must spend before
                    checking out. Default to 0 if you don't require a limit.
                  </>
                }
                className='_ml-8'
              >
                <QuestionCircleOutlined />
              </Tooltip>
            </>
          }
        >
          <Input
            type='number'
            step='0.01'
            min='0.0'
            addonBefore='£'
            style={{ width: '160px' }}
            disabled={!canManage}
            required
            data-testid='minimum-order-value'
            onFocus={onFocusScrollNumber}
            onBlur={onBlurScrollNumber}
          />
        </Form.Item>
        <Divider />
      </div>
      <Form.Item className='_mt-32'>
        <Row justify='space-between'>
          <Col>
            <Button
              title='Cancel'
              className='_center-vertical _ml-auto'
              type='ghost'
              onClick={resetForm}
            >
              Cancel
            </Button>
          </Col>
          <Col>
            <Button
              loading={loading}
              title='Save'
              className='_ml-auto _center-vertical'
              disabled={DisallowSave()}
              onClick={submit}
            >
              {loading ? 'Saving' : 'Save'}
            </Button>
          </Col>
        </Row>
      </Form.Item>
    </Form>
  )
}

export default DeliveryForm
