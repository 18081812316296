import { uuid } from 'uuidv4'
import groupBy from 'lodash/groupBy'
import flatten from 'lodash/flatten'
import uniq from 'lodash/uniq'
import { Discount } from '@slerp/controls'

export const formatProductParams = (
  products: { [key: string]: string[] },
  discount_id: string
) => {
  return uniq(flatten(Object.values(products || {}))).map((id: string) => ({
    discount_id,
    product_id: id,
    id: uuid(),
    inserted_at: 'now()',
    updated_at: 'now()'
  }))
}

export const formatCategoryParams = (
  categories: { [key: string]: string[] },
  discount_id: string
) => {
  return flatten(Object.values(categories || {})).map((id: string) => ({
    discount_id,
    category_id: id,
    id: uuid(),
    inserted_at: 'now()',
    updated_at: 'now()'
  }))
}

export const formatCategoryValues = (categories: { category_id: string }[]) =>
  categories.map((item: { category_id: string }) => item.category_id)

export const formatProductValues = (
  products_discount: {
    product: { products_categories: { category_id: string }[] }
    product_id: string
  }[]
) => {
  let categorizedProducts: { (key: string): string[] } | {} = {}

  products_discount.forEach(({ product, product_id }) => {
    product.products_categories.forEach(({ category_id }) => {
      categorizedProducts = {
        ...categorizedProducts,
        [category_id]: [...(categorizedProducts[category_id] || []), product_id]
      }
    })
  })

  return categorizedProducts
}

export const getDiscountProperties = ({
  target,
  trigger,
  store_ids,
  merchantStoresDeliveryPricingBands
}: Discount) => {
  const isDelivery = target === 'delivery_fee'
  const isAutomatic = trigger === 'automatic'
  const isAvailableAllStores = (store_ids || []).length === 0

  const storeIdsWithPricingBands = (store_ids || []).filter((storeId: string) =>
    merchantStoresDeliveryPricingBands.includes(storeId)
  )

  const isBlockedByActivePricingBands =
    isDelivery &&
    isAutomatic &&
    ((!!merchantStoresDeliveryPricingBands.length && isAvailableAllStores) ||
      !!storeIdsWithPricingBands.length)

  return {
    isDelivery,
    isAutomatic,
    isAvailableAllStores,
    isBlockedByActivePricingBands
  }
}
