import { message } from 'antd'

type MERCHANT_LAYOUT_SETTING = {
  merchants_by_pk: {
    slug: string
    integration_settings: {
      composer: {
        id: string
        enabled: boolean
      }
    }
  }
}

export const allowComposerSSO = (data: MERCHANT_LAYOUT_SETTING) => {
  return (
    data?.merchants_by_pk?.integration_settings?.composer?.enabled &&
    Boolean(data?.merchants_by_pk?.integration_settings?.composer?.id)
  )
}

export const getComposerEnabled = (data: MERCHANT_LAYOUT_SETTING) => {
  return data?.merchants_by_pk?.integration_settings?.composer?.enabled
}

export const getComposerId = (data: MERCHANT_LAYOUT_SETTING) => {
  return data?.merchants_by_pk?.integration_settings?.composer?.id
}

export const handleComposerSSO = (data: MERCHANT_LAYOUT_SETTING) => {
  if (!data?.merchants_by_pk?.integration_settings?.composer?.enabled) {
    message.destroy()
    message.error('Composer space not created!')

    return
  }

  const token = localStorage.getItem('token')
  const slug = data?.merchants_by_pk?.integration_settings?.composer?.id
  const scheme = process.env.NODE_ENV === 'production' ? 'https' : 'http'
  const url = `${scheme}://${process.env.REACT_APP_COMPOSER_HOST}/api/sso`

  const postParams = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-JWT-Token': `${token}`,
      'Access-Control-Request-Headers': 'Content-Type,X-JWT-Token'
    },
    body: JSON.stringify({ slug: slug })
  }

  message.destroy()
  message.success('Logging in to Composer!')

  fetch(url, postParams)
    .then((response) => response.json())
    .then((data) => {
      if (data.response === 201) {
        window.open(data.sso_url)
      }
    })
    .catch((error) => {
      message.destroy()
      message.error('Unable to login to Composer!')
    })
}
