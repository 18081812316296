import React from 'react'
import {
  HomeIcon,
  OrdersIcon,
  AppsIcon as ProductsIcon,
  HelpIcon,
  LocationIcon,
  TagIcon as DiscountsIcon,
  CustomersIcon,
  PuzzleIcon as DesignerIcon,
  BoltIcon as LoyaltyIcon,
  BoxIcon as ComposerIcon,
  SlerpLogo
} from '@slerp/assets'
import { Menu, Layout } from 'antd'
import { ShakeOutlined, MailOutlined } from '@ant-design/icons'

import { Link } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { HELP_CENTRE_URL } from '@slerp/client'
import { isAdmin, useSession } from '@slerp/accounts'
import { LAYOUT_QUERY_MERCHANT_SETTING } from './LayoutQueries'
import { MERCHANT_LAYOUT_SETTING, crmUrl } from './utils/features'
import { handleComposerSSO, allowComposerSSO } from './../Composer/utils'

const { Sider } = Layout

const Sidebar = (props: any) => {
  const { merchant, user } = useSession()
  const { data } = useQuery<MERCHANT_LAYOUT_SETTING>(
    LAYOUT_QUERY_MERCHANT_SETTING,
    {
      variables: { merchant: merchant.id }
    }
  )
  const isNotAnAdmin = !isAdmin(user)

  const currentActiveTabChecker = () => {
    const currentPath = window.location.pathname

    if (currentPath.indexOf('/heat-map') === 0) return 'home'
    if (currentPath.indexOf('/order') === 0) return 'orders'
    if (currentPath.indexOf('/location') === 0) return 'locations'
    if (currentPath.indexOf('/product') === 0) return 'products'
    if (currentPath.indexOf('/modifiers') === 0) return 'products'
    if (currentPath.indexOf('/discount') === 0) return 'discounts'
    if (currentPath.indexOf('/customer') === 0) return 'customers'
    if (currentPath.indexOf('/loyalty') === 0) return 'loyalty'
    if (currentPath.indexOf('/designer') === 0) return 'designer'
    if (currentPath.indexOf('/designer/mobile-app') === 0) return 'designer'
    if (currentPath.indexOf('/designer/content') === 0) return 'designer'
    if (currentPath.indexOf('/settings') === 0) return 'settings'
    if (currentPath.indexOf('/app-campaigns') === 0) return 'app-campaigns'
    return 'home'
  }

  return (
    <Sider
      data-testid='layout-sidebar-component'
      trigger={null}
      collapsible
      width={240}
      collapsedWidth={66}
      collapsed={props.collapsed}
    >
      <div className='brand'>
        <SlerpLogo />
      </div>
      <Menu
        mode='inline'
        defaultSelectedKeys={[currentActiveTabChecker()]}
        className='sidebar-nav'
      >
        <Menu.Item key='home'>
          <Link to='/' className='sidebar-tooltip'>
            <HomeIcon className='anticon' />
            <span>Overview</span>
          </Link>
        </Menu.Item>
        <Menu.Item key='orders' hidden={isNotAnAdmin}>
          <Link to='/orders' className='sidebar-tooltip'>
            <OrdersIcon className='anticon' />
            <span>Orders</span>
          </Link>
        </Menu.Item>
        <Menu.Item key='locations'>
          <Link to='/locations' className='sidebar-tooltip'>
            <LocationIcon className='anticon' />
            <span>Locations</span>
          </Link>
        </Menu.Item>
        <Menu.Item key='products' hidden={isNotAnAdmin}>
          <Link to='/products' className='sidebar-tooltip'>
            <ProductsIcon className='anticon' />
            <span>Products</span>
          </Link>
        </Menu.Item>
        <Menu.Item key='discounts' hidden={isNotAnAdmin}>
          <Link to='/discounts' className='sidebar-tooltip'>
            <DiscountsIcon className='anticon' />
            <span>Discounts</span>
          </Link>
        </Menu.Item>
        <Menu.Item key='customers' hidden={isNotAnAdmin}>
          <Link to='/customers' className='sidebar-tooltip'>
            <CustomersIcon className='anticon' />
            <span>Customers</span>
          </Link>
        </Menu.Item>
        <Menu.Item key='loyalty' hidden={isNotAnAdmin}>
          <Link to='/loyalty' className='sidebar-tooltip'>
            <LoyaltyIcon className='anticon' />
            <span>Loyalty</span>
          </Link>
        </Menu.Item>
        <Menu.Item key='designer' hidden={isNotAnAdmin}>
          <Link to='/designer' className='sidebar-tooltip'>
            <DesignerIcon className='anticon' />
            <span>Designer</span>
          </Link>
        </Menu.Item>
        {data && !allowComposerSSO(data) && (
          <Menu.Item
            key='composer'
            className='sidebar-composer'
            hidden={isNotAnAdmin}
          >
            <Link to='/composer' className='sidebar-tooltip'>
              <ComposerIcon className='anticon' />
              <span>Composer</span>
            </Link>
          </Menu.Item>
        )}
        {data && allowComposerSSO(data) && (
          <Menu.Item
            key='composer'
            className='sidebar-composer'
            hidden={isNotAnAdmin}
          >
            <a
              onClick={() => handleComposerSSO(data)}
              className='sidebar-tooltip'
            >
              <ComposerIcon className='anticon' />
              <span>Composer</span>
            </a>
          </Menu.Item>
        )}
        {data && (
          <Menu.Item key='crm' className='sidebar-crm' hidden={isNotAnAdmin}>
            <a
              href={crmUrl(data)}
              className='sidebar-tooltip'
              target='_blank'
              rel='noreferrer'
            >
              <MailOutlined
                className='anticon'
                style={{
                  lineHeight: 1,
                  fontSize: '20px'
                }}
              />
              <span>Slerp CRM</span>
            </a>
          </Menu.Item>
        )}
        <Menu.Item
          key='app-campaigns'
          className='sidebar-settings'
          hidden={isNotAnAdmin}
        >
          <Link
            to={
              data?.merchants_by_pk?.app_settings[0]?.enabled
                ? '/app-campaigns'
                : '/designer/mobile-app'
            }
            className='sidebar-tooltip'
          >
            <ShakeOutlined
              className='anticon'
              style={{
                lineHeight: 1,
                fontSize: '20px'
              }}
            />
            <span style={{ whiteSpace: 'nowrap' }}>App Campaigns</span>
          </Link>
        </Menu.Item>
        <Menu.Item key='help-centre' className='sidebar-help'>
          <a
            href={HELP_CENTRE_URL}
            target='_blank'
            rel='noopener noreferrer'
            className='sidebar-tooltip'
          >
            <HelpIcon className='anticon' />
            <span>Help Centre</span>
          </a>
        </Menu.Item>
      </Menu>
    </Sider>
  )
}

export default Sidebar
