import moment from 'moment-timezone'
import isEmpty from 'lodash/isEmpty'
import {
  QUERY_LOYALTY_CARD_NAME_INSERT_VALIDATION,
  QUERY_LOYALTY_CARD_NAME_UPDATE_VALIDATION
} from './LoyaltyQueries'
import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client'
import { GRAPHQL_ENDPOINT } from '@slerp/client'

export type CustomRuleType = { getFieldValue: (arg: string) => string }

const required = { required: true, message: 'This is a required field' }
const requiredRule = [required]
const requiredProduct = [
  { required: true, message: 'Please select at least one product.' }
]
const requiredCategory = [
  { required: true, message: 'Please select at least one category.' }
]

const requiredImageRule = [
  {
    required: true,
    message: 'Please add a photo'
  }
]

const startDateRule = [
  ({ getFieldValue }: CustomRuleType) => ({
    validator(_: any, value: moment.Moment) {
      const endDate = getFieldValue('ends_at')

      if (isEmpty(endDate) || isEmpty(value)) {
        return Promise.resolve()
      }

      const isValid = value.diff(endDate, 'seconds') <= 0
      return isValid
        ? Promise.resolve()
        : Promise.reject(
            'Please input an End date that is after the Start date'
          )
    }
  })
]

const nonZeroRule = [
  required,
  {
    validator: (_: any, val: number | string) =>
      val === 0 || val === '0'
        ? Promise.reject('Value cannot be set to zero')
        : Promise.resolve()
  }
]

const nonNegativeRule = [
  {
    validator: (_: any, val: number | string) => {
      const num = val && Number(val)
      return num < 0
        ? Promise.reject('Value cannot be negative')
        : Promise.resolve()
    }
  }
]

const nonEmptySelectRule = [
  {
    validator: (_: any, val: string[]) => {
      return isEmpty(val)
        ? Promise.reject('Please select at least one')
        : Promise.resolve()
    }
  }
]

const rewardValueRule = [
  ...nonZeroRule,
  ({ getFieldValue }: CustomRuleType) => ({
    validator: (_: any, val: number | string) => {
      if (getFieldValue('reward_value_type') === 'percentage') {
        return val > 100.0
          ? Promise.reject('Max value exceeded')
          : Promise.resolve()
      } else {
        return Promise.resolve()
      }
    }
  })
]

const parseJson = (value?: string) => {
  try {
    return JSON.parse(value)
  } catch (e) {
    return {}
  }
}

const userDetails = localStorage.getItem('user') || undefined
const user = parseJson(userDetails)

const client = new ApolloClient({
  link: new HttpLink({
    uri: GRAPHQL_ENDPOINT,
    headers: {
      authorization: user?.api_key
    }
  }),
  cache: new InMemoryCache()
})

const uniqueName = [
  ({ getFieldValue }) => ({
    validator: (_: any, currentValue: any) => {
      const cardId = getFieldValue('id')
      const merchantId = getFieldValue('merchant_id')

      if (!currentValue) return Promise.resolve()

      // Combined 3 rules due to Heap Memory error

      // HTML tag rule
      if (/<[^>]*>/.test(currentValue)) {
        return Promise.reject('HTML Tags are not allowed')
      } else {
        // character limit rule
        if (currentValue.length <= 30) {
          if (cardId) {
            return client
              .query({
                query: QUERY_LOYALTY_CARD_NAME_UPDATE_VALIDATION,
                variables: {
                  merchantId,
                  loyaltyCardId: cardId,
                  name: currentValue
                },
                errorPolicy: 'ignore',
                fetchPolicy: 'no-cache'
              })
              .then((result) => {
                if (result?.data?.loyalty_cards?.length > 0)
                  return Promise.reject(
                    'A loyalty card with this name exists. Please try another name.'
                  )
                return Promise.resolve()
              })
              .catch((error) => {
                return Promise.reject(error)
              })
          }

          return client
            .query({
              query: QUERY_LOYALTY_CARD_NAME_INSERT_VALIDATION,
              variables: { merchantId, name: currentValue },
              errorPolicy: 'ignore',
              fetchPolicy: 'no-cache'
            })
            .then((result) => {
              if (result?.data?.loyalty_cards?.length > 0)
                return Promise.reject(
                  'A loyalty card with this name exists. Please try another name.'
                )
              return Promise.resolve()
            })
            .catch((error) => {
              return Promise.reject(error)
            })
        } else {
          return Promise.reject('Maximum of 30 characters')
        }
      }
    }
  })
]

const reqProductIdsRule = [
  ({ getFieldValue }: CustomRuleType) => ({
    validator(_: any, value: moment.Moment) {
      const productIds = getFieldValue('product_variant_ids') || []
      return productIds.length > 0
        ? Promise.resolve()
        : Promise.reject('Please select at least one product.')
    }
  })
]

const reqRewardProductIdsRule = [
  ({ getFieldValue }: CustomRuleType) => ({
    validator(_: any, value: moment.Moment) {
      const productIds = getFieldValue('reward_product_ids') || []
      return productIds.length > 0
        ? Promise.resolve()
        : Promise.reject('Please select at least one product.')
    }
  })
]

export {
  startDateRule,
  requiredRule,
  requiredImageRule,
  requiredCategory,
  requiredProduct,
  nonZeroRule,
  rewardValueRule,
  nonNegativeRule,
  uniqueName,
  reqProductIdsRule,
  reqRewardProductIdsRule,
  nonEmptySelectRule
}
